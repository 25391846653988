// import js
import React from "react";

interface ExternalUrlProps {
    href: string,
    rel: string,
    children: React.ReactNode
}

function ExternalUrl(props: ExternalUrlProps) {
    return <a href={props.href} rel={props.rel} onClick={(evt) => {
        evt.preventDefault();
        newWnd(props.href);
    }}>{props.children}</a>
}

function newWnd(url: string) {
    let wnd = window.open(url);
    if (wnd != null) wnd.opener = null;
    return wnd;
}

export default ExternalUrl